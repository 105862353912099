<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 13:38:52
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-10-10 10:46:24
-->
<template>
  <div>
    <router-link :to="'myTasksDetails?taskId='+item.taskId" class="item">
      <div class="main">
        <div class="line_1">
          <span class="name">{{item.ecompanyJobCategoryName}}</span>
          <span class="city">{{item.cityName?item.cityName:"不限"}}</span>
        </div>
        <div class="line_2" v-html="item.taskName"></div>
        <div class="line_3">
          {{item.taskMoney=="费用面议"?"":item.amount}}
          <span
            :class="{only:item.amount==undefined||item.amount.length == 0||item.taskMoney == '费用面议'}"
          >
            <template v-if="item.taskMoney == '费用面议'">费用面议</template>
            <template v-else-if="item.taskMoney == '固定价格'">/元</template>
            <template v-else-if="item.taskMoney == '按月'">元/月</template>
            <template v-else-if="item.taskMoney == '按周'">元/周</template>
            <template v-else-if="item.taskMoney == '按天'">元/天</template>
            <template v-else-if="item.taskMoney == '按时'">元/小时</template>
          </span>
        </div>
      </div>
      <div class="right">
        <el-button type="warning" v-if="item.employeeTaskStatus == 'applying'">申请中</el-button>
        <el-button type="primary" v-else-if="item.employeeTaskStatus == 'finish'">已完成</el-button>
        <el-button type="danger" v-else-if="item.employeeTaskStatus == 'nopass'">未通过</el-button>
        <el-button type="info" v-else-if="item.employeeTaskStatus == 'cancelled'">已作废</el-button>
        <template v-else-if="item.employeeTaskStatus == 'doing'">
          <el-button type="primary" v-if="item.contractStatus == 'wsign'" @click.stop.prevent="toSign(item)">去签署</el-button>
          <el-button type="success" v-else-if="item.contractStatus == 'signed'">交付任务</el-button>
          <el-button type="success" v-else>进行中</el-button>
        </template>
        <p>
          申请时间:{{sliceData(item.applyDate)}}
          <!-- <template v-if="item.lastEndDate == undefined">长期有效</template>
          <template
            v-else-if="(timeLeftCount(item.lastApplyDate) <= 0) || (item.applyStatus == 'stop')"
          >已截止</template>
          <template v-else>{{item.lastEndDate}}</template>-->
        </p>
      </div>
    </router-link>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center>
      <div class="dialog">
        <span class="dialog-text">请先用微信扫描下方二维码，关注【工蚁宝】微信公众号完成个体户认证与注册</span>
        <el-image 
        class="dialog-img"
        :src="url">
      </el-image>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/list";
import {
  itemStatusHttpAction,
} from "@/api/status";

export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible:false,
      url: require('@/assets/header_qrcode.jpg'),
    }
  },
  methods: {
    timeLeftCount(data) {
      return new Date(data.replace(/-/g, "/")).getTime() - new Date().getTime();
    },
    sliceData(data) {
      if (data && data.length == 10) {
        return data.slice(5);
      }
    },
    toSign(item){
      api.getRelationIndividual({ id: item.ocompanyId }, { method: "get" })
      .then((res) => {
        console.log('getRelationIndividual',res);
        if (res.status == 200) {
          if(!res.data){
            // 自由职业者人力公司，跳转至灵工签署页面
            this.$router.push({path:'/userCenter/signContract',query: {contractId: item.contractEmployeeId}})
          }else{
            // 个体户人力公司，跳转至个体户签署页面，跳转至个体户签署页面时，需要判断当前关联的个体户是否注册成功
            itemStatusHttpAction(item.ocompanyId).then((res) => {
              console.log('itemStatusHttpAction',res)
              if (res.status == "200"&&res.data) {
                // 成功：跳转至个体户签署页面
                this.$router.push({path:'/userCenter/signContractIndividual',query: {contractId: item.contractEmployeeId,ocompanyId: item.ocompanyId}})
              }else {
                // 提示去微信认证
                this.dialogVisible = true;
              }
            });
          }
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.item {
  position: relative;
  display: block;
  background: #fff;
  line-height: 36px;
  padding: 20px 28px;
  border-bottom: 1px solid #f5f5f5;
  .main {
    display: inline-block;
    width: 80%;
    .line_1 {
      margin-bottom: -8px;
      .name {
        display: inline-block;
        vertical-align: text-bottom;
        font-size: 22px;
        color: #333;
        margin-right: 20px;
        max-width: 10em;
      }

      .city {
        display: inline-block;
        vertical-align: text-bottom;
        color: #666666;
        font-size: 18px;
        max-width: 10em;
      }
    }

    .line_2 {
      font-size: 16px;
      height: 36px;
      margin-bottom: 4px;
      color: #999;
      cursor: pointer;

      /deep/ p {
        margin: 0;
      }
    }

    .line_3 {
      color: #f2641e;
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      margin-right: 12px;
      span {
        font-size: 14px;

        &.only {
          font-size: 22px;
        }
      }
    }
  }

  .right {
    position: absolute;
    right: 30px;
    top: 20px;
    /deep/ .el-button {
      display: block;
      width: 110px;
      padding: 8px;
      font-size: 18px;
      border-radius: 2px;
      margin: 0 auto;
    }

    p {
      font-size: 14px;
      color: #999;
      text-align: right;
      margin-top: 40px;
    }
  }

  .type {
    position: absolute;
    left: 60%;
    top: 50%;
    margin-top: -46px;
    display: block;
    width: 92px;
    height: 92px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    &.end {
      background: url(./assets/end.png);
    }
  }
  .dialog{
    text-align: center;
    .dialog-text{
      display: block;
      color: #8493FA;
    }
    .dialog-img{
      max-width: 300px; 
      max-height: 300px
    }
  }
}
</style>
